.head-container {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  height: 100vh;
  margin-top: -10vh;
}

#tsparticles > canvas {
  position: absolute;
  z-index: 0 !important;
  z-index: -999;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
}

.description {
  max-width: 50vw;
}

.name {
  font-size: calc(33px + 2vw);
}

.aboutme-btn {
  background: linear-gradient(
    124deg,
    transparent,
    transparent,
    transparent,
    var(--text-sec),
    transparent,
    transparent
  );
  background-size: 1800% 1800%;
  animation: rainbow 9s ease infinite;
  z-index: 1;
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }

  50% {
    background-position: 100% 19%;
  }

  100% {
    background-position: 0% 82%;
  }
}

@media screen and (max-width: 600px) {
  .description {
    max-width: 100%;
  }
}
