@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Splash&display=swap');

:root {
  --background: #1e1e1e;
  --sec-backg: #343434;
  --shadow: rgb(0, 0, 0, 0.2);
  --text-primary: #fff;
  --text-sec: rgba(255, 255, 255, 0.6);
  --error-bgcolor: rgba(255, 0, 0, 0.121);
  --blury-background: rgba(0, 0, 0, 0.6);

  --font-stack: "Raleway";
  --main-font-size: calc(16px + 0.39vw);
}

[data-theme="light"] {
  --background: #fff;
  --sec-backg: #f1f1f1;
  --text-primary: #1e1e1e;
  --text-sec: rgb(160, 160, 160);
  --blury-background: rgba(230, 230, 230, 0.6);
}

* {
  margin: 0;
  font-family: var(--font-stack);
  font-size: var(--main-font-size);
}

html {
  scroll-behavior: smooth;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  border-bottom-right-radius: 10px;
  background: var(--text-primary);
  transform-origin: 0%;
  z-index: 3;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 15px;
}

body {
  background-color: var(--background);
}

h1 {
  font-size: calc(30px + 0.390625vw);
  font-family: var(--bold-font-stack);
}

.title {
  font-size: 40px;
}

.app {
  padding-inline: 10%;
  background: linear-gradient(-120deg, var(--sec-backg), var(--background), var(--background));
  color: var(--text-primary);
  font-size: var(--main-font-size);
  transition: all 0.5s;
}

.quote {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.quote > p {
  font-family: "Splash";
  line-height: 85%;
  font-size: 2.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-root {
  font-family: inherit !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-transform: none !important;
  border-radius: 12px !important;
}

.MuiButton-outlined {
  color: var(--text-primary) !important;
  border: 1px solid var(--text-primary) !important;
  background-color: var();
}

.MuiButton-contained {
  background-color: var(--sec-backg) !important;
  color: var(--text-primary) !important;
  box-shadow: none !important;
}

.MuiButton-text {
  background-color: transparent;
  color: var(--text-primary) !important;
}

.MuiButton-outlined:hover {
  background-color: var(--text-primary) !important;
  color: var(--sec-backg) !important;
}

.MuiSvgIcon-root {
  color: inherit;
}

@media screen and (max-width: 800px) {
  .app {
    padding-inline: 4%;
  }
}
