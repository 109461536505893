footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 70px 0;
}

.footer {
    display: flex;
}

.social-media > * {
    color: var(--text-primary) !important;
}

.heart {
    margin-inline: 5px;
    animation: beat .25s infinite alternate;
	transform-origin: center;
}

@keyframes beat{
	to { transform: scale(1.4); }
}