.header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.logoContainer {
  font-size: 30px;
  font-family: var(--font-stack);
  font-weight: 600;
  cursor: pointer;
  z-index: 7;
}

.headerItems {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  z-index: 1;
}

.headerItems > a {
  margin-inline: 20px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.navbar-burger {
  display: none;
}

@media screen and (max-width: 500px) {
  .navbar-burger {
    display: flex;
    position: fixed;
    right: 1.5rem;
    padding: 10px 0;
    background: none;
    border: none;
    z-index: 3;
  }

  .navbar-burger:hover {
    cursor: pointer;
  }
  .headerItems {
    backdrop-filter: blur(17px);
    background-color: var(--blury-background);
    bottom: 0;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: fixed;
    right: -100vw;
    transition: all .5s ease-in-out;
    visibility: hidden;
    width: 0;
    z-index: 3;
  }

  .show-nav .headerItems {
    right: 0;
    width: 100vw;
    visibility: visible;
  }

  .headerItems > a {
    margin-inline: 20px;
    margin-block: 0;
    cursor: pointer;
  }

  .headerItems > div {
    margin-left: 30px;
  }

  .burger-bar,
  .burger-bar::before,
  .burger-bar::after {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: var(--text-primary);
    transition: all 0.5s ease-in-out;
  }

  .burger-bar::before,
  .burger-bar::after {
    content: "";
    position: absolute;
    left: 0;
  }

  .burger-bar::before {
    transform: translateY(-12px);
  }

  .burger-bar::after {
    transform: translateY(12px);
  }

  .show-nav .burger-bar::before {
    transform: rotate(-45deg);
  }

  .show-nav .burger-bar::after {
    transform: rotate(45deg);
  }

  .show-nav .burger-bar {
    background: transparent;
  }

  .headerItems > a::after{
    content: "";
    display: block;
    margin: 0 auto;
    width: 4vw;
    height: 1px;
    background: var(--text-primary);
    margin-block: 20px;
  }

  .headerItems > a:last-child::after{
    display: none;
  }
}
