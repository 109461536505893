.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 0 20vw;
}

.form-container {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  background-color: var(--sec-backg);
  border-radius: 15px;
  transition: all 0.5s !important;
  overflow: hidden;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-thanks {
  position: absolute;
  opacity: 0;
  padding: 40% 0;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 0;
  background-color: var(--blury-background);
  backdrop-filter: blur(6px);
  color: var(--text-sec);
  z-index: 0;
  transition: all 0.5s !important;
}

.contact-description {
  max-width: 400px;
  margin: auto;
}

.MuiInputLabel-root {
  color: var(--text-sec) !important;
  font-family: inherit !important;
}

.MuiFilledInput-root::after {
  border-bottom: 2px solid var(--text-primary) !important;
}

.MuiFilledInput-input {
  color: var(--text-primary) !important;
  font-family: var(--font-stack) !important;
}

.MuiFormHelperText-root {
  color: var(--text-primary) !important;
  font-family: var(--font-stack) !important;
}

@media screen and (max-width: 800px) {
  .contact-container {
    padding: 40px;
  }
}

@media screen and (max-width: 500px) {
  .contact-container {
    padding: 0;
  }
}
